import { GuardService } from "./base";
import router from "@/router";
import storage from "@/storage";
import { IAuthStorage } from "../storage/model";
import { api } from '@cems-eco/core/src/services/api';
import { whiteLabel } from "@cems-eco/core/src/utils";
import { widget } from "@cems-eco/core/src/utils";


export class CloudGuardService extends GuardService {


    async checkAuthentication() {
        // this.getAccess();
        if (!await this.isAuthenticated()) {
            return router.replace('/');
        }

        if (!await this.isSiteSelected()) {
            return router.replace('/auth/site');
        }

        return router.replace('/monitor');
    }


    async checkProviderStatus() {
        const response: any = await api.get<any>(`/pilot/${whiteLabel.provider()}/status`);
        if (response.data.status) return;

        widget.alert(response.message, response.data.message, 'error');
        this.logout();
        throw {message: response.data.message};
    }

    async isSiteSelected() {
        const site_auth = await storage.get<IAuthStorage>(storage.SITE_AUTH);
        return site_auth != null;
    }

}
