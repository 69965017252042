import modules from "@/module";
import { menu } from '@cems-eco/core/src/utils';

menu.setMenu(modules);


export default [
  menu.getDivider(),
  menu.getHeader("MENU"),
  menu.getMenu("monitor"),
  menu.getMenu("maintenance"),
  menu.getMenu("management"),
  menu.getMenu("report"),
  menu.getMenu("iremote"),
];