import storage from "@/storage";
import { IAuthStorage, IAccessStorage, IUserStorage, ILoginStorage } from "@/storage/model";
import { BaseAuthBloc } from "./bloc.base";

export class CloudAuthBloc extends BaseAuthBloc {
    async saveStorage(data: any): Promise<any> {
        const auth: IAuthStorage = data.auth;
        await storage.set<IAuthStorage>(storage.AUTH, auth);

        const access: IAccessStorage = data.configuration.access;
        await storage.set<IAccessStorage>(storage.ACCESS, access);

        const permission: string[] = data.configuration.user_permission;
        await storage.set<string[]>(storage.PERMISSION, permission);

        const user: IUserStorage = data.user;
        await storage.set<IUserStorage>(storage.USER, user);

        const loginToken: ILoginStorage = data.login_token;
        await storage.set<ILoginStorage>(storage.LOGIN_TOKEN, loginToken);

        const alarm = true;
        await storage.set<any>(storage.ALARM, alarm);
    }
}