import { defineModuleProvider, platform } from "@cems-eco/core/src/utils";

export default defineModuleProvider({
    name: "auth",
    routes: [
        {
            path: '/',
            redirect: '/auth/login',
        },
        {
            path: '/auth/login',
            name: 'Login',
            meta: { layout: "login" },
            component: () => import(/* webpackChunkName: "auth.login" */ `./pages/login/login.${platform.get()}.vue`),
        },
        {
            path: '/auth/site',
            name: 'Site Login',
            meta: { layout: "blank" },
            component: () => import(/* webpackChunkName: "auth.site" */ `./pages/site/site.vue`),
        },
        {
            path: '/auth/auto-login/:key',
            name: 'Auto Login',
            meta: { layout: "blank" },
            component: () => import(/* webpackChunkName: "auth.auto-login" */ `./pages/auto-login/auto-login.vue`),
        },

    ],
    menu: []
});