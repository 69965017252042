import { defineModuleProvider } from "@cems-eco/core/src/utils";

export default defineModuleProvider({
    name: "iremote",
    routes: [
        {
            path: '/iremote',
            name: 'iremote',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'iRemote', menuClass : "fas fa-industry text-primary" },
            component: () => import(/* webpackChunkName: "iremote" */ './pages/layout.vue'),
            children: [
                {
                    path: '',
                    name: 'iremote.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/iremote', parentName: 'iRemote', breadcrumb: 'iRemote' },
                    component: () => import(/* webpackChunkName: "iremote.index" */ `./pages/index/index.vue`),
                },
            ]
        },

    ],
    menu: [
        {
            id: "iremote",
            class: "fas fa-industry text-primary",
            type: "single",
            name: "iRemote",
            route: "/iremote",
        },
    ]
});