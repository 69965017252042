import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

import SideMenu from "./side-menu/side-menu.vue";
import TabMenu from "./tab-menu/tab-menu.vue";

import TopMenu from "./top-menu/top-menu.vue";

export default platform.service(environment.nexus, {
    box: {
        menu: {
            side: SideMenu,
            top: TopMenu,
            tab: TabMenu,
        },
    },
    cloud: {
        menu: {
            side: SideMenu,
            top: TopMenu,
            tab: TabMenu,
        },
    },
    demo: {
        menu: {
            side: SideMenu,
            top: TopMenu,
            tab: TabMenu,
        },
    },
});