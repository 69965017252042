import { NexusStorage } from "@cems-eco/core/src/services/storage";
import { Service } from "typedi";
import Container from "typedi";
@Service()
class StorageService extends NexusStorage  {
    [key: string]: any;
    readonly USER: string = 'user';
    readonly ACCESS: string = 'access';
    readonly PERMISSION: string = 'permission';
    readonly CLIENT: string = 'client';
    readonly LOGIN_TOKEN: string = 'login_token';
    readonly CLIENT_ADV_SEARCH: any = 'client_adv_search';
    readonly SITE_ADV_SEARCH: any = 'site_adv_search';
    readonly EQUIPMENT_ADV_SEARCH: any = 'equipment_adv_search';
    readonly SENSOR_ADV_SEARCH: any = 'sensor_adv_search';
    readonly USER_ADV_SEARCH: any = 'user_adv_search';
    readonly MAINTENANCE_ADV_SEARCH: any = 'maintenance_adv_search';
    readonly ALARM: any = 'alarm';
    readonly SELECTED_EQUIPMENT: any = 'selected_equipment';
    readonly SITE_AUTHS: any = 'site_auths';

    constructor(){
        super();
        this.init();
    }
}


const service = Container.get(StorageService);
export default service;