import { defineModuleProvider, platform } from "@cems-eco/core/src/utils";

export default defineModuleProvider({
    name: "maintenance",
    routes: [
        {
            path: '/maintenance',
            name: 'maintenance',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Maintenance', menuClass : "far fa-calendar text-primary" },
            component: () => import(/* webpackChunkName: "maintenance" */ './pages/layout.vue'),
            children: [
                {
                    path: '',
                    name: 'maintenance.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/maintenance', parentName: 'Maintenance', breadcrumb: 'Maintenance' },
                    component: () => import(/* webpackChunkName: "maintenance.index" */ `./pages/index/index.${platform.get()}.vue`),
                },
                {
                    path: ':id',
                    name: 'maintenance.view',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/maintenance', parentName: 'Maintenance', breadcrumb: 'View', permissionName: 'View Maintenance' },
                    component: () => import(/* webpackChunkName: "maintenance.view" */ `./pages/view/view.vue`),
                },
                {
                    path: 'create',
                    name: 'maintenance.create',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/maintenance', parentName: 'Maintenance', breadcrumb: 'Create', permissionName: 'Create Maintenance' },
                    component: () => import(/* webpackChunkName: "maintenance.create" */ `./pages/create/create.vue`),
                },
                {
                    path: ':id/edit',
                    name: 'maintenance.edit',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/maintenance', parentName: 'Maintenance', breadcrumb: 'Edit', permissionName: 'Edit Maintenance' },
                    component: () => import(/* webpackChunkName: "maintenance.edit" */ `./pages/edit/edit.vue`),
                },
            ]
        },

    ],
    menu: [
        {
            id: "maintenance",
            class: "far fa-calendar text-primary",
            type: "single",
            name: "Maintenance",
            route: "/maintenance",
        },
    ]
});