import 'reflect-metadata';

import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import NexusApp from '@cems-eco/core';
import config from "@/config";
import components from "@/components";

const app = createApp(App)
.use(NexusApp
        .app(config)
        .plugin(), 
    {  components }
)
.use(router);

// .mount('#app')
router.isReady().then(() => app.mount('#app'))