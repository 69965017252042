import { BaseAuthApi } from './api.base';
import { api } from '@cems-eco/core/src/services/api';

export class BoxAuthApi extends BaseAuthApi {
    async getUrl(endpoint: string) {
        return api.API_URL + endpoint;
    }

    async healthcheck() {
        const response = await api.get<any>("/healthcheck", true);
        return response.data;
    }
}
