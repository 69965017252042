import AuthProvider from "@/modules/auth/provider";
import MonitorProvider from "@/modules/monitor/provider";
import IRemoteProvider from "@/modules/iremote/provider";
import ReportProvider from "@/modules/report/provider";
import MaintenanceProvider from "@/modules/maintenance/provider";
import ManagementProvider from "@/modules/management/provider";

import type { ModuleProvider } from "@cems-eco/core/src/utils";

export default [
    AuthProvider,
    IRemoteProvider,
    MonitorProvider,
    ReportProvider,
    MaintenanceProvider,
    ManagementProvider
] as ModuleProvider[];
