import { Site } from "./index";
import api from "./api";

export abstract class BaseTopMenuBloc {

    async getSite(id: any): Promise<Site> {
        let response = await api.getSite(id)
        return response.data;
    }

    async logout(id: any): Promise<void> {
        let response = await api.logout(id)
        return response.data;
    }

}

