import { platform } from "@cems-eco/core/src/utils";
import { BoxTopMenuBloc } from "./bloc.box";
import { CloudTopMenuBloc } from "./bloc.cloud";
import { DemoTopMenuBloc } from "./bloc.demo";
import { BaseTopMenuBloc } from "./bloc.base";
import environment from "@/config";

export default platform.service<BaseTopMenuBloc>(environment.nexus, {
    box: new BoxTopMenuBloc(),
    cloud: new CloudTopMenuBloc(),
    demo: new DemoTopMenuBloc(),
});