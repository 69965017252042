import { api } from '@cems-eco/core/src/services/api';

export abstract class BaseTopMenuApi {

    
    constructor() {
    }

    async getSite(id: any): Promise<any> {
        return await api.get<any>(`/site/${id}`, true);
    }


    async logout(data: any): Promise<any> {
        return await api.post<any>(`/activity/logout`, data, true);
    }

}
