import { GuardService } from "./base";
import router from "@/router";


export class BoxGuardService extends GuardService {
    async checkAuthentication() {
        // this.getAccess();
        if (!await this.isAuthenticated()) {
            return router.replace('/');
        }

        return router.replace('/monitor');
    }

    checkProviderStatus() {
        return true;
    }


}
