
import { BoxTopMenu } from "./top-menu.box";
import { CloudTopMenu } from "./top-menu.cloud";
import { DemoTopMenu } from "./top-menu.demo";
import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

export default class TopMenu extends platform.mixins(environment.nexus, {
  box: BoxTopMenu,
  cloud: CloudTopMenu,
  demo: DemoTopMenu
}){

}
