import domains from "./white-label"
import { whiteLabel } from "@cems-eco/core/src/utils";

export default {
    production: false,
    nexus: <string>process.env.VUE_APP_NEXUS,
    BASE_URL: process.env.VUE_APP_API_BASE_ENDPOINT,
    API_URL: process.env.VUE_APP_API_ENDPOINT,
    PROXY_API_URL: process.env.VUE_APP_PROXY_API_ENDPOINT,
    CEMS_RTC: {
      URL: <string>process.env.VUE_APP_API_RTC_ENDPOINT
    },
    white_label: whiteLabel.config(domains)
};
