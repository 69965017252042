
import modules from "@/module";


let routes: Array<any> = [];

for (const key in modules) {
    modules[key].routes.forEach((route: any) => {
        routes.push(route);
    });
}

export default routes;
