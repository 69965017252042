import { defineModuleProvider, platform } from "@cems-eco/core/src/utils";

export default defineModuleProvider({
    name: "management",
    routes: [
        {
            path: '/management',
            name: 'management',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Management', menuClass: "far fa-file-alt text-primary" },
            component: () => import(/* webpackChunkName: "management" */ `./pages/layout.vue`),
            children: [
                {
                    path: '',
                    name: 'management.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/management', parentName: 'Management', breadcrumb: 'Management', permissionName: 'View Management' },
                    component: () => import(/* webpackChunkName: "management.index" */ `./pages/index/index.${platform.get()}.vue`),
                },
                {
                    path: ':id/edit',
                    name: 'management.edit',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/management/', parentName: 'Management', breadcrumb: 'Edit', permissionName: 'Edit Management' },
                    component: () => import(/* webpackChunkName: "management.edit" */ `./pages/edit/edit.vue`),
                },
                {
                    path: ':id/document',
                    name: 'management.document',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/management', parentName: 'Management', breadcrumb: 'Document', permissionName: 'Edit Management' },
                    component: () => import(/* webpackChunkName: "management.document" */ `./pages/document/document.vue`),
                },
            ]
        },

    ],
    menu: [
        {
            id: "management",
            class: "far fa-file-alt text-primary",
            type: "single",
            name: "Management",
            route: "/management",
        },
    ]
});