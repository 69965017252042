import modules from "@/module";


const moduleMenus: Map<string, any> = new Map();
for (const key in modules) {
    modules[key].menu.forEach((menu: any) => {
        moduleMenus.set(menu.id, menu);
    });
}

export const getMenu = (key: string) =>
  moduleMenus.has(key) ? moduleMenus.get(key) : ({} as any);

export default [
  getMenu("maintenance"),
  getMenu("iremote"),
  getMenu("monitor"),
  getMenu("report"),
];