import { defineModuleProvider, platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

export default defineModuleProvider({
    name: "report",
    routes: [
        {
            path: '/report',
            name: 'report',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Report', menuClass : "fas fa-file-medical-alt text-primary" },
            component: () => import(/* webpackChunkName: "report" */ './pages/layout.vue'),
            children: [
                {
                    path: '',
                    name: 'report.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/report', parentName: 'Report', breadcrumb: 'Report' },
                    component: () => import(/* webpackChunkName: "report.index" */ `./pages/index.${platform.get()}.vue`),
                },
            ]
        },

    ],
    menu: [
        {
            id: "report",
            class: "fas fa-file-medical-alt text-primary",
            type: "single",
            name: "Report",
            route: "/report",
        },
    ]
});