import { defineModuleProvider, platform } from "@cems-eco/core/src/utils";

export default defineModuleProvider({
    name: "monitor",
    routes: [
        {
            path: '/monitor',
            name: 'monitor',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Monitor', menuClass : "ni ni-tv-2 text-primary" },
            component: () => import(/* webpackChunkName: "monitor" */ './pages/layout.vue'),
            children: [
                {
                    path: '',
                    name: 'monitor.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/monitor', parentName: 'Monitor', breadcrumb: 'Monitor' },
                    component: () => import(/* webpackChunkName: "monitor.index" */ `./pages/index/index.vue`),
                },
                {
                    path: ':id',
                    name: 'monitor.view',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/monitor', parentName: 'Monitor', breadcrumb: 'View'},
                    component: () => import(/* webpackChunkName: "monitor.view" */ `./pages/view/view.${platform.get()}.vue`),
                },
            ]
        }

    ],
    menu: [
        {
            id: "monitor",
            class: "ni ni-tv-2 text-primary",
            type: "single",
            name: "Monitor",
            route: "/monitor",
        },
    ]
});