import { widget } from "@cems-eco/core/src/utils";
import router from "@/router";
import { Subscription } from "rxjs";
import { IAccessStorage, IAuthStorage, IAlarmStorage } from "../storage/model";
import storage from "@/storage";
export abstract class GuardService {

    access: any = {}
    subscription: Subscription;
    constructor() {
        // this.getAccess();
    }

    async isAuthenticated(): Promise<boolean> {
        const auth = await storage.get<IAuthStorage>(storage.AUTH);
        return auth != null;
    }

    async logout() {
        router.replace('/');
        await storage.clearAll();
        this.subscription?.unsubscribe();
    }

    async getAccess() {
        this.access = await storage.get<IAccessStorage>(storage.ACCESS);
        this.subscription = storage.observable<IAccessStorage>(storage.ACCESS)
            .subscribe((data) => {
                if (Object.keys(data).length) this.access = data;
            });
    }

    async menuAccess(menu) {
        try {
            await this.getAccess();
            const accessList: any[] = Object.keys(this.access).map((a) => a.toLocaleLowerCase());
            return accessList.includes(menu.toLocaleLowerCase());
        } catch (error) {
            return false
        }
    }

    async submenuAccess(moduleName, submenu) {
        try {
            await this.getAccess();
            const selectedAccess = this.access[moduleName.toLocaleLowerCase()];

            if (!selectedAccess) return false;

            const accessList: any[] = selectedAccess.map((a) => a.toLocaleLowerCase());

            return accessList.includes(submenu.name.toLocaleLowerCase());
        } catch (error) {
            return false
        }
    }

    async pageAccess(to, from, next) {
        try {
            const permissionName = to.meta?.permissionName;

            if (!(await this.isAuthenticated())) {
                widget.alert('Access Denied', 'You dont have permission to access this page', 'error');
                return router.replace('/');
            }

            const permission = await storage.get<string[]>(storage.PERMISSION);

            if (permissionName && !permission.includes(permissionName))
                return widget.alert('Access Denied', 'You dont have permission to access this page', 'error');

            return next();
        } catch (e) {
            console.log(e);
        }
    }

    abstract checkAuthentication();
    abstract checkProviderStatus();

}
