
import modules from "@/module";
import guard from "@/guard";


let routes: Array<any> = [];

for (const key in modules) {
    modules[key].routes.forEach((route: any) => {
        routes.push(route);
    });
}


routes = routes.map((route) => ({
    ...route,
    children: route.children?.map((r) => ({
        ...r,
        beforeEnter: (to, from, next) => {
            guard.pageAccess(to, from, next);
        },
    })),
}));

export default routes;