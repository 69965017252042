
import { Vue } from "vue-class-component";

import storage from "@/storage";
import { ISiteStorage, IUserStorage } from "@/storage/model";
import topMenuBloc from "./services/bloc";
import guard from "@/guard";
import authBloc from "@/modules/auth/services/bloc";

export abstract class BaseTopMenu extends Vue {
  loginBloc = authBloc;
  topMenuBloc = topMenuBloc
  searchFocusClass: string = "w-25";
  options: any = null;
  showSearch = false;
  openSearch = false;
  focusSearch = false;
  username = '';
  userId = '';
  showAlarm = false;
  routePathEquipment: string = "";
  alarmMessage: string = "";
  showSite = true;
  isShow = false;

  async mounted() {
    try {
      const siteStorage: ISiteStorage = await storage.get<ISiteStorage>(storage.SITE);
      const site = await this.topMenuBloc.getSite(siteStorage.id);
      if (site) {
        this.options = site;
      }
    } catch (e) {

    }
  }


  async goAuthSite() {
    await storage.clear(storage.SITE_AUTH);
    this.$router.push(`/auth/site`);
  }


  goToMonitor() {
    if (this.routePathEquipment == "") return;
    this.$router.push(`/monitor/${this.routePathEquipment}/`);
  }

  openMenu() {
    const open = 'nav-open g-sidenav-show g-sidenav-pinned';
    const close = 'g-sidenav-hidden';
    document.body.className = document.body.className == close ? open : close;
  }

  async logout() {
    const userStorage: IUserStorage = await storage.get<IUserStorage>(storage.USER);
    if (userStorage) {
      let obj = { id: userStorage.id };
      try {
        this.topMenuBloc.logout(obj);
      } catch (e) { }
    }
    await guard.logout();
  }

  async toggleActionSheet() {
    this.isShow = !this.isShow;
  }
}