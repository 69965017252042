
import { Login } from "./index";
import authApi from "./api";
import storage from "@/storage";
import { IAccessStorage, IAuthStorage, IClientStorage, ISiteStorage, IUserStorage, ILoginStorage } from '@/storage/model';
import { isEmpty } from "lodash";

export abstract class BaseAuthBloc {
    module = 'auth';
    api = authApi;

    async login(login: Login): Promise<any> {
        let response = await this.api.login(login)
        return response.data;
    }

    async autoLogin(key: string): Promise<any> {
        let response = await this.api.autoLogin(key)
        return response;
    }

    async getSite(): Promise<any> {
        let response = await this.api.getSite()
        return response.data;
    }
    async healthcheck(hash: any): Promise<any> {
        return await this.api.healthcheck(hash);
    }

    async site(site: any): Promise<any> {
        const site_auths = await storage.get<IAuthStorage>(storage.SITE_AUTHS);
        let auth: IAuthStorage;
        let doLogin = false;
        let auths: any = {};
        if (isEmpty(site_auths)) {
            doLogin = true;
        } else {
            auths = site_auths;
            if (isEmpty(site_auths[site.id])) {
                doLogin = true;
            }
        }

        if (doLogin) {
            let response = await this.api.site(site.id);
            auth = response.data.auth;
            auths[site.id] = response.data.auth;
            await storage.set(storage.SITE_AUTHS, auths);
        } else {
            auth = site_auths[site.id];
        }
        await storage.set<IAuthStorage>(storage.SITE_AUTH, auth);
        await storage.set<IClientStorage>(storage.CLIENT, site.client);
        await storage.set<ISiteStorage>(storage.SITE, site);
    }

    abstract saveStorage(data: any): Promise<any>;
}