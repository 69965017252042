import { BaseAuthApi } from './api.base';
import { apiNet } from '@cems-eco/core/src/services/api';
import axios from "axios";

export class CloudAuthApi extends BaseAuthApi {


    async getUrl(endpoint: string, hash: string  = "") {
        return apiNet.API_URL + endpoint + (endpoint.includes("?") ? `&` : `?`) + `hash=${hash}`;
    }

    async healthcheck(hash: string) {
        return new Promise(async (resolve, reject) => {
            const headers: any = {
                responseType: "json",
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
            const url = await this.getUrl(`/healthcheck?hash=${hash}`);
            axios.get(url, headers).then((response: any) => {
                resolve(response.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}
