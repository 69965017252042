import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_global_style = _resolveComponent("n-global-style")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, { "theme-overrides": _ctx.themeOverrides }, {
    default: _withCtx(() => [
      _createVNode(_component_n_global_style),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    ]),
    _: 1
  }, 8, ["theme-overrides"]))
}