
import { BaseTopMenu } from "./top-menu";
import { Options } from "vue-class-component";
import TopMenu from "./base/top-menu.base.vue";
import router from "@/router";

export class DemoTopMenu extends BaseTopMenu {
    async created() {
      this.username = "Admin";
    }
  
    async logout() {
      router.replace('/');

    }

    async mounted() {
      this.options = {
        name: "Sri Kembangan",
        id: 1
      };
    }
}