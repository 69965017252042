import { createRouter, createWebHashHistory } from 'vue-router';
import BoxRoute from "./box";
import CloudRoute from "./cloud";
import DemoRoute from "./demo";
import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

const routes = platform.service(environment.nexus, {
    box: BoxRoute,
    cloud: CloudRoute,
    demo: DemoRoute,
});

export default createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: (to, from, savedPosition) => savedPosition ?? { top: 0 },
});