import { BoxGuardService } from "./box";
import { CloudGuardService } from "./cloud";
import { platform } from "@cems-eco/core/src/utils";
import { GuardService } from "./base";
import environment from "@/config";

export default platform.service<GuardService>(environment.nexus, {
    box: new BoxGuardService(),
    cloud: new CloudGuardService(),
});
