import { BoxTopMenuApi } from "./api.box";
import { CloudTopMenuApi } from "./api.cloud";
import { DemoTopMenuApi } from "./api.demo";
import { BaseTopMenuApi } from "./api.base";
import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

export default platform.service<BaseTopMenuApi>(environment.nexus, {
    box: new BoxTopMenuApi(),
    cloud: new CloudTopMenuApi(),
    demo: new DemoTopMenuApi(),
});