import { api, apiNet } from '@cems-eco/core/src/services/api';

export abstract class BaseAuthApi {
    private BASE_URL: string;

    constructor() {
        this.BASE_URL = "/auth/login";
    }

    async login(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async getSite(): Promise<any> {
        return await api.get<any>("/client/selectSite", true);
    }

    async site(id): Promise<any> {
        return await api.post<any>("/auth/site", { id }, true);
    }

    async autoLogin(key): Promise<any> {
        return await api.post<any>("/auth/auto-login", { key }, true);
    }

    abstract getUrl(endpoint: string, hash: string);
    abstract healthcheck(hash: string);
}
