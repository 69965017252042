
import { BaseTopMenu } from "./top-menu";
import { IUserStorage } from "@/storage/model";
import storage from "@/storage";

export class BoxTopMenu extends BaseTopMenu {
    async created() {
      const userStorage: IUserStorage = await storage.get<IUserStorage>(storage.USER);
      this.username = userStorage.name;

      this.showSite = false;
    }
}