
import { Vue } from "vue-class-component";
import { whiteLabel } from "@cems-eco/core/src/utils";
import { GlobalThemeOverrides, NConfigProvider } from 'naive-ui';
import guard from "@/guard";

export default class App extends Vue {
  default = "main";

  themeOverrides: GlobalThemeOverrides = {
    common: {
      // baseColor: '#5e72e4',
      primaryColor: '#5e72e4',
      primaryColorHover: '#5e72e4',
      primaryColorPressed: '#5e72e4',
      primaryColorSuppl: '#5e72e4',
    },
  }

  get layout() {
    return (this.$route.meta.layout || this.default) + "-layout";
  }

  async created() {
    whiteLabel.setup();

    await guard.checkProviderStatus();
  }
}
